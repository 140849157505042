<template>
	<div class="layer-list" :class="{ 'border-bottom': borderBottom }">
		<v-list-group sub-group @click.native.stop="opened = !opened" :value="opened" :ripple="false" :append-icon="undefined" active-class="active">
			<template #prependIcon>
				<v-icon v-if="!!$slots.default" size="13" class="high">
					fal
					{{ opened ? 'fa-minus' : 'fa-plus' }}
				</v-icon>
				<div v-else />
			</template>

			<template #activator>
				<v-list-item-title class="high font-weight-700 ml-n4"
					>{{ title }}<v-icon size="10" color="gray" class="ml-2"> fal fa-info-circle </v-icon></v-list-item-title
				>

				<v-icon v-if="groupId == 'STOP_PREVISION_GROUP_ID'" size="10" color="grey" class="ml-2"> fas fa-clock </v-icon>
			</template>
			<template #appendIcon v-if="!group">
				<div class="d-flex align-center">
					<pui-switch
						color="var(--primary)"
						class="mt-0 pt-0"
						v-model="computedActive"
						@click.native.stop=""
						@input="(value) => (computedActive = value)"
					>
					</pui-switch>
				</div>
			</template>

			<v-list-item class="pa-3">
				<slot />
			</v-list-item>
		</v-list-group>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: Boolean,
			required: true
		},

		active: {
			type: Boolean,
			required: true
		},

		title: {
			type: String,
			require: true
		},

		borderBottom: {
			type: Boolean,
			default: false
		},
		group: {
			type: Boolean,
			default: false
		},
		groupId: {
			type: String,
			require: true
		}
	},

	computed: {
		opened: {
			get() {
				return this.value;
			},

			set(value) {
				this.$emit('input', value);
			}
		},

		computedActive: {
			get() {
				return this.active;
			},

			set(value) {
				this.$emit('update:active', value);
			}
		}
	}
};
</script>

<style lang="postcss" scoped>
* {
	font-size: 1rem !important;
}

.layer-list {
	& .border-bottom {
		border-bottom: 1px #fff solid;
	}

	& .high {
		color: var(--high) !important;
	}

	& .font-weight-700 {
		font-weight: 700;
	}
}
</style>
