<template>
	<div class="d-flex flex-column settingsmappanel fill-height max-height-100">
		<div class="d-flex flex-column py-6 px-3 mb-0" ref="header">
			<div class="settings-title">{{ $t('operations.layers.title') }}</div>
		</div>
		<div class="pb-0 px-3">
			<label class="text-subtitle-1 font-weight-bold">{{ $t('operations.layers.mapview.label') }}</label>

			<v-autocomplete itemText="text" solo flat hide-details toplabel :items="mapOptions" v-model="typeM" reactive :key="typeKey">
			</v-autocomplete>
		</div>

		<div class="pb-0 px-3" v-if="showPortsOptions">
			<label class="text-subtitle-1 font-weight-bold">{{ $t('operations.portfilter.title') }}</label>
			<v-autocomplete
				itemText="portname"
				itemValue="id"
				toplabel
				solo
				flat
				hide-details
				clearable
				:items="ports"
				v-model="port"
				reactive
			></v-autocomplete>
		</div>

		<div class="lists pb-0 px-3" v-if="this.isMapAisId">
			<v-row>
				<v-col cols="1" class="mr-4"><v-icon class="font-weight-bold" color="var(--primary)" size="13">fas fa-layer-group</v-icon> </v-col>
				<v-col cols="9">
					<p class="maintitle ml-n4">{{ $t('operations.layers.name') }}</p>
				</v-col>
			</v-row>
			<layers-list ref="layerlist" />
		</div>
	</div>
</template>

<script>
import LayersList from './LayersList';
import {
	STATUS_VIS,
	TYPE_VIS,
	OPERATIONS_VIS,
	MAP_AIS_ID,
	MAP_PERIODS_ID
} from '@/components/operations/map/sections/map/subcomponents/mapVariables';

/** Utils */
import mapUtils from '@/components/operations/map/mapUtils';

import ol2map from '@/components/operations/map/sections/map/subcomponents/ol2map';

export default {
	name: 'settingsmappanel',

	components: {
		LayersList
	},

	props: {
		currentmap: {
			type: String,
			required: false
		}
	},

	created() {
		if (this.mapAisIsLoaded && this.isMapAisId && this.ports.length == 1) {
			this.zoomToExtent();
		}
		this.typeKey++;
	},

	data() {
		return {
			mapOptions: [
				{
					text: this.$t('operations.layers.mapview.status'),
					value: STATUS_VIS
				},
				{
					text: this.$t('operations.layers.mapview.shiptype'),
					value: TYPE_VIS
				},
				{
					text: this.$t('operations.layers.mapview.operations'),
					value: OPERATIONS_VIS
				}
			],
			typeM:
				this.currentmap == MAP_AIS_ID ? this.$store.getters.getMapAisStyleVisualization : this.$store.getters.getMapPeriodsStyleVisualization,
			typeKey: 0,
			port: this.currentmap == MAP_AIS_ID ? this.$store.getters.getMapAisPortVisualization : this.$store.getters.getMapPeriodsPortVisualization
		};
	},
	methods: {},

	computed: {
		isMapAisId() {
			return this.currentmap == MAP_AIS_ID;
		},
		isMapPeriodsId() {
			return this.currentmap == MAP_PERIODS_ID;
		},
		mapAisIsLoaded() {
			return this.$store.getters.getMapAisIsLoaded;
		},
		mapPeriodsIsLoaded() {
			return this.$store.getters.getMapPeriodsIsLoaded;
		},
		isOpened() {
			return this.$store.state.settingsPanel.isOpened;
		},
		ports() {
			return this.$store.state.session.properties.PORTS.filter(
				(port) => port.portauthorityid == window.localStorage.getItem('workingPortAuthorityId')
			);
		},
		showPortsOptions() {
			return this.ports.length > 1;
		}
	},
	created() {
		if (this.ports.length == 1) {
			this.port = this.$store.state.session.properties.PORTS[0].id;
		}
	},
	watch: {
		'$store.state.mappanelais.mapAisIsLoaded'() {
			if (this.ports.length == 1) {
				const portObj = this.ports.find((port) => port.id === this.port);
				if (portObj) {
					const fitExtent = mapUtils.polygonWktToOlExtent(portObj.bbox);
					ol2map.zoomToExtent(fitExtent);
				}
			}
		},
		'$store.state.mappanelperiods.mapPeriodsIsLoaded'() {
			if (this.ports.length == 1) {
				const portObj = this.ports.find((port) => port.id === this.port);
				if (portObj) {
					const fitExtent = mapUtils.polygonWktToOlExtent(portObj.bbox);
					ol2map.zoomToExtent(fitExtent);
				}
			}
		},
		typeM() {
			if (this.isMapAisId) {
				this.$store.commit('setMapAisStyleVisualization', this.typeM);
			} else {
				this.$store.commit('setMapPeriodsStyleVisualization', this.typeM);
			}
		},
		port() {
			if (this.mapAisIsLoaded && this.isMapAisId) {
				this.$store.commit('setMapAisPortVisualization', this.port);
				const portObj = this.ports.find((port) => port.id === this.port);
				if (portObj) {
					const fitExtent = mapUtils.polygonWktToOlExtent(portObj.bbox);
					this.$store.commit('setMapAisFitPortExtent', fitExtent);
					ol2map.zoomToExtent(fitExtent);
				}
			} else if (this.mapPeriodsIsLoaded && this.isMapPeriodsId) {
				this.$store.commit('setMapPeriodsPortVisualization', this.port);
				const portObj = this.ports.find((port) => port.id === this.port);
				if (portObj) {
					const fitExtent = mapUtils.polygonWktToOlExtent(portObj.bbox);
					this.$store.commit('setMapPeriodsFitPortExtent', fitExtent);
					ol2map.zoomToExtent(fitExtent);
				}
			}
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../../styles/app-variables.pcss';

.v-text-field.v-select.v-text-field--edited .v-input__control .v-input__slot {
	border: 2px solid #f7f8fc !important;
	background-color: #f7f8fc !important;
}
.settingsmappanel {
	background-color: #f7f8fc;

	& .text-subtitle-1 {
		font-family: Overpass, Montserrat, sans-serif !important;
	}

	& .list-icon {
		color: var(--primary);

		&--opened {
			transform: rotateZ(-90deg) !important;
		}
	}

	& .lists {
		& .maintitle {
			font-weight: 700;
			font-size: 1.1rem;
			font-weight: bold;
			color: var(--primary);
		}
	}

	& .settings-title {
		font-size: 1.3rem;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.2;
		letter-spacing: normal;
		color: var(--high);
		margin-bottom: 2px;
	}

	& .settings-subtitle {
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: var(--medium);
		margin-top: 2px !important;
	}
}
</style>
