<template>
	<div class="layerslist d-flex flex-column py-0 pl-0 pr-0" style="width: 100%">
		<div class="mt-1 mb-4 d-flex align-center justify-end subheader">
			<!-- Selection controls: All & Nothing -->
			<span class="selector" @click="changeAll(true)">{{ $t('operations.layers.all') }}</span>
			<span class="ml-2 mr-2">|</span>
			<span class="selector" @click="changeAll(false)">{{ $t('operations.layers.none') }}</span>
		</div>

		<!-- Layer List -->
		<v-list dense style="background: transparent !important" class="layer-list py-0">
			<layer-list-title
				v-model="aisVesselGroupOpened"
				:active="aisVesselGroupActive"
				@update:active="(value) => toggleAll('aisVesselGroup', value)"
				:title="$t('operations.layers.ais')"
				border-bottom
				groupId="AIS_VESSEL_GROUP_ID"
			>
				<filter-ais-layers-list ref="aisVesselGroup" @click.native.stop="" :active.sync="aisVesselGroupActive" />
			</layer-list-title>
		</v-list>

		<v-list dense style="background: transparent !important" class="layer-list py-0">
			<layer-list-title
				v-model="stopPrevisionGroupOpened"
				:active="stopPrevisionGroupActive"
				@update:active="(value) => toggleAll('stopPrevisionGroup', value)"
				:title="$t('operations.layers.prevision')"
				border-bottom
				groupId="STOP_PREVISION_GROUP_ID"
			>
				<prevision-layers-list ref="stopPrevisionGroup" @click.native.stop="" :active.sync="stopPrevisionGroupActive" />
			</layer-list-title>
		</v-list>

		<v-list dense style="background: transparent !important" class="layer-list py-0">
			<layer-list-title
				:value="cepZonesLayerOpened"
				:active="cepZonesLayerActive"
				@update:active="(value) => setActive('cepZonesLayer', value)"
				:title="$t('operations.layers.zones')"
				border-bottom
			>
			</layer-list-title>
		</v-list>
	</div>
</template>

<script>
import ol2map from '@/components/operations/map/sections/map/subcomponents/ol2map';
import {
	AIS_VESSEL_GROUP_ID,
	AIS_VESSEL_LAYER_ID,
	FILTER_AIS_VISIT_LAYER_ID,
	FILTER_SERVICE_LAYER_ID,
	FILTER_OTHER_VESSEL_LAYER_ID,
	STOP_PREVISION_GROUP_ID,
	STOP_ACCEPTED_LAYER_ID,
	STOP_PLANNED_LAYER_ID,
	STOP_AUTHORIZED_LAYER_ID,
	STOP_CONFIRMED_LAYER_ID,
	STOP_INITIATED_LAYER_ID,
	CEP_ZONES_ID
} from '@/components/operations/map/constants/layers';
import {} from '@/components/operations/map/constants/layers';
import LayerListTitle from './subpanels/common/LayerListTitle';
import PrevisionLayersList from './subpanels/PrevisionLayersList';
import FilterAisLayersList from './subpanels/FilterAISLayersList';

const SUBGROUPS = {
	[AIS_VESSEL_GROUP_ID]: [AIS_VESSEL_LAYER_ID, FILTER_AIS_VISIT_LAYER_ID, FILTER_SERVICE_LAYER_ID, FILTER_OTHER_VESSEL_LAYER_ID],
	[STOP_PREVISION_GROUP_ID]: [
		STOP_ACCEPTED_LAYER_ID,
		STOP_PLANNED_LAYER_ID,
		STOP_AUTHORIZED_LAYER_ID,
		STOP_CONFIRMED_LAYER_ID,
		STOP_INITIATED_LAYER_ID
	],
	[CEP_ZONES_ID]: [CEP_ZONES_ID]
};

export default {
	name: 'layerssection',

	data() {
		return {};
	},

	components: {
		LayerListTitle,
		PrevisionLayersList,
		FilterAisLayersList
	},

	computed: {
		[`${AIS_VESSEL_GROUP_ID}Opened`]: {
			get() {
				return this.$store.state.mappanelais.layers[AIS_VESSEL_GROUP_ID].opened;
			},

			set(value) {
				this.$store.state.mappanelais.layers[AIS_VESSEL_GROUP_ID].opened = value;
			}
		},

		[`${STOP_PREVISION_GROUP_ID}Opened`]: {
			get() {
				return this.$store.state.mappanelais.layers[STOP_PREVISION_GROUP_ID].opened;
			},

			set(value) {
				this.$store.state.mappanelais.layers[STOP_PREVISION_GROUP_ID].opened = value;
			}
		},

		[`${CEP_ZONES_ID}Opened`]: {
			get() {
				return this.$store.state.mappanelais.layers[CEP_ZONES_ID].opened;
			},

			set(value) {
				this.$store.state.mappanelais.layers[CEP_ZONES_ID].opened = value;
			}
		},

		[`${AIS_VESSEL_GROUP_ID}Active`]: {
			get() {
				return this.$store.state.mappanelais.layers[AIS_VESSEL_GROUP_ID].active;
			},

			set(value) {
				this.$store.state.mappanelais.layers[AIS_VESSEL_GROUP_ID].active = value;
			}
		},

		[`${STOP_PREVISION_GROUP_ID}Active`]: {
			get() {
				return this.$store.state.mappanelais.layers[STOP_PREVISION_GROUP_ID].active;
			},

			set(value) {
				this.$store.state.mappanelais.layers[STOP_PREVISION_GROUP_ID].active = value;
			}
		},

		[`${CEP_ZONES_ID}Active`]: {
			get() {
				return this.$store.state.mappanelais.layers[CEP_ZONES_ID].active;
			},

			set(value) {
				this.$store.state.mappanelais.layers[CEP_ZONES_ID].active = value;
			}
		},

		layers() {
			return this.$store.getters.layers;
		}
	},

	methods: {
		changeAll(value) {
			Object.keys(this.layers).forEach((layer) => this.toggleAll(layer, value));

			if (!value) {
				this.$puiNotify.info(this.$t('operations.layers.noLayers'));
			}
		},

		toggleAll(layer, value) {
			this[`${layer}Active`] = value;
			SUBGROUPS[layer].forEach((subgroup) => {
				this.$store.state.mappanelais.layers[layer][subgroup] = value;
				ol2map.setVisibility(subgroup, value);
			});
		},

		setActive(layer, active) {
			this.$store.state.mappanelais.layers[layer]['active'] = active;
			// CEP_ZONES_ID es el la unica que actua como capa.
			ol2map.setVisibility(CEP_ZONES_ID, active);
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../../styles/app-variables.pcss';
.layerslist {
	& .subheader {
		font-size: 10px;
		color: var(--primary);

		& .selector {
			color: var(--primary);
			font-size: 0.9rem;
			line-height: 1.33;

			&:hover {
				cursor: pointer;
				font-weight: 900;
			}
		}
	}

	& .bottomborder {
		border-bottom: 1px solid var(--moderate);
	}
}
.border-bottom {
	border-bottom: 2px solid #dadde0 !important;
}
</style>
